<template>
  <div class="main">
    <el-form class="" :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="box center mB20 box-bottom-border" style="padding-bottom: 20px;">
        <el-form-item label="分析时段" prop="startTime">
          <el-date-picker placeholder="请选择时间" type="datetime" @change="clearTable" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.startTime"> </el-date-picker>
        </el-form-item>
        <span class="line">-</span>
        <el-form-item prop="endTime">
          <el-date-picker @change="clearTable" placeholder="请选择时间" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.endTime"> </el-date-picker>
        </el-form-item>
        <el-button :class="!numDetail.trendType ? 'disable_icon' : ''" @click="querryData" class="mL44 dataQuery">查询 </el-button>
      </div>
      <div class="box center mB20 box-bottom-border" style="padding-bottom: 20px;">
        <el-form-item label="分析测点" prop="type">
          <el-select @change="changeType" v-model="ruleForm.type">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="ml24" prop="quota">
          <el-select @change="changeQuota" v-model="ruleForm.quota">
            <el-option v-for="item in quotaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="ml24" prop="num" style="margin-right: 44px;">
          <el-select class="numSel" @change="changeNum" v-model="ruleForm.num">
            <el-option v-for="item in numList" :key="item.id" :label="item.code" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分析测点" prop="type">
          <span>{{ numDetail.trendType === 1 ? `多项式（阶数：${numDetail.trendOrder}）` : numDetail.trendType === 2 ? "指数" : "未启用" }}</span>
        </el-form-item>
      </div>
     
    </el-form>
    <div class="noDataMain" v-if="!showMain">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
    <div  v-if="showMain" class="chartMain">
      <!--      <span class="fs22 title">测点编号：{{targetName}}</span>-->
      <div class="box box_column smallTableMain">
        <div class="newBox mB10">
          <div class="textC title mB10">趋势分析数据</div>
          <div id="myChart2"></div>
        </div>
        <div class="newBox">
          <div class="mT10 textC title mB10">趋势分析结果</div>
          <div id="myChart"></div>
        </div>
        <el-table v-if="false" border :data="msgList">
          <el-table-column align="center" label="趋势变化率">
            <el-table-column align="center" width="82px" prop="name"></el-table-column>
            <el-table-column align="center" width="202px" prop="value"></el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import moment from "moment";
let option = {
  tooltip: {
    show: true,
    trigger: "axis"
  },
  grid: {
    top: "30",
    left: "90",
    right: "8",
    bottom: "90"
  },
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 40,
      end: 60,
      bottom: 10,
      fillerColor: "rgba(47,50,51,1)"
      // yAxisIndex: [0],
      // filterMode: "empty"
    },
    {
      type: "inside",
      realtime: true,
      start: 40,
      end: 60
    }
  ],
  xAxis: {
    type: "category",
    axisLabel: {
      color: "#fff",
      fontSize: 10,
      formatter: function (value) {
        return value.split(" ").join("\n");
      },
      // interval:18,    //显示间隔
      rotate: -20 //旋转-20度
    },
    axisLine: {
      lineStyle: {
        color: "#9D9D9D",
        type: "dashed"
      }
    },
    axisTick: {
      show: false
    },
    data: []
  },
  yAxis: [
    {
      type: "value",
      name: "作用效应",
      nameTextStyle: {
        color: "#fff",
        fontSize: 14
      },
      axisLabel: {
        color: "#fff",
        fontSize: 14
      },
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      splitLine: {
        lineStyle: {
          color: "#9D9D9D",
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      axisTick: {
        show: false
      }
    }
  ],
  series: [
    {
      name: "Y值",
      data: [],
      type: "line",
      // symbol:'none',
      itemStyle: {
        color: "#6DB5C7"
      },
      lineStyle: {
        width: 1
      }
    }
  ]
};
export default {
  name: "temAnalysis",
  data() {
    return {
      firstTime: true,
      ruleForm: { quota: null, type: null, num: null, startTime: "", endTime: "" },
      rules: {},
      typeList: [],
      quotaList: [],
      numList: [],
      size: 50000, // 为了显示几天的条数，暂定50000条，杨博士
      showMain: false,
      targetName: "",
      targetUnit: "",
      targetId: "",
      msgList: [
        { value: "", name: "月度" },
        { value: "", name: "季度" },
        { value: "", name: "年度" }
      ],
      numDetail: { trendType: 0 },
      dataType: 13
    };
  },
  mounted() {
    this.changeTimeDay(1);
    this.projectId = this.$store.state.projectId;
    this.getTime();
    this.getTypeList(); // 请求监测类型
  },
  methods: {
    changeTimeDay(n) {
      var day = new Date();
      var day1 = day.getTime(); //获得当前时间戳
      var day2 = day1 - n * 24 * 60 * 60 * 1000;
      var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      var end = moment(day).format("YYYY-MM-DD HH:mm:ss");

      this.ruleForm.startTime = start;
      this.ruleForm.endTime = end;
    },
    clearTable() {
      // this.showMain = false;
      this.tableData = [];
    },
    getTime() {
      // this.ruleForm.endTime = new Date().Format('yyyy-MM-dd hh:mm')
      // this.ruleForm.startTime = new Date(new Date().getTime() - 24*60*60*1000).Format('yyyy-MM-dd hh:mm')
    },
    valiTime() {
      let endTime = new Date(this.ruleForm.endTime).getTime() / 1000 / 60;
      let startTime = new Date(this.ruleForm.startTime).getTime() / 1000 / 60;
      let cha = (endTime - startTime) / 60 / 24;
      if (cha < 0) {
        this.$message({
          message: "开始时间应早于结束时间!",
          type: "warning"
        });
        return false;
      } else if (cha > 365) {
        this.$message({
          message: "查询时间间隔应小于365天!",
          type: "warning"
        });
        return false;
      }
      return true;
    },
    changeType(val) {
      this.clearTable();
      // this.getQuotaList()
      this.quotaList = [];
      this.numList = [];
      this.ruleForm.quota = "";
      this.ruleForm.num = "";
      this.getQuotaTypeList();
    },
    changeQuota(val) {
      this.clearTable();
      this.numList = [];
      this.ruleForm.num = "";
      this.getNumList();
    },
    changeNum(val, item) {
      console.log(val);
      console.log(item);
      this.clearTable();
      this.getNumDetail();
    },
    querryData() {
      console.log("this.numDetail.trendType", this.numDetail.trendType, "this.valiTime()", this.valiTime());
      if (!this.numDetail.trendType) return;
      if (!this.valiTime()) return;
      // this.showMain = true;
      this.getLineData();
      this.getLineData2();
    },
    initEcharts(id, xData, yData) {
      let thisOption = { ...option };
      thisOption.yAxis[0].name = this.targetUnit;
      let name = "";
      let quotaList = this.quotaList;
      for (let item of quotaList) {
        if (this.ruleForm.quota === item.id) {
          name = item.name;
        }
      }
      thisOption.series[0].name = name + "趋势值";
      let max = Math.max.apply(null, yData).toFixed(3);
      let min = Math.min.apply(null, yData).toFixed(3);
      thisOption.yAxis[0].max = max;
      thisOption.yAxis[0].min = min;
      thisOption.xAxis.data = xData;
      thisOption.series[0].data = yData;
      switch (id) {
        case "myChart":
          thisOption.series[0].name = name + "趋势值";
          thisOption.series[0].itemStyle.color = "#DBF804";
          // this.myChart1 = echarts.init(document.getElementById(id));
          // this.myChart1.setOption(thisOption);
          // window.addEventListener("resize", () => {
          //   this.myChart1.resize();
          // });
          name = name + "趋势值";
          var lineColor = '#54a6ff'
          this.$chart2.line1('myChart', xData, yData, name, lineColor, max, min);

          break;
        case "myChart2":
          thisOption.series[0].name = "分析数据";
          thisOption.series[0].itemStyle.color = "#6DB5C7";

          // this.myChart2 = echarts.init(document.getElementById(id));
          // console.log("mychart2", this.myChart2);
          // this.myChart2.setOption(thisOption);
          // window.addEventListener("resize", () => {
          //   this.myChart2.resize();
          // });

          name = '分析数据';
          var lineColor = '#86eaff';
          this.$chart2.line1('myChart2', xData, yData, name, lineColor, max, min);

          break;
      }
      var chart1 = echarts.init(document.getElementById('myChart'))
      var chart2 = echarts.init(document.getElementById('myChart2'))
      echarts.connect([chart1, chart2])
      // this.myChart = echarts.init(document.getElementById('myChart'))
      // option.yAxis[0].name=this.targetUnit
    },
    look(target) {
      this.targetId = target.id;
      this.targetName = target.code;
      this.targetUnit = target.unit;
      console.log(target);
      // this.targetId = 82
      this.showMain = true;
      this.getLineData();
      this.getLineData2();
    },
    getTypeList() {
      // this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
      let params = {
        dataType: this.dataType,
        projectId: sessionStorage.getItem("projectId")
      };

      this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.typeList = res.data;
          this.ruleForm.type = res.data[0].id;
          // this.getQuotaList()
          this.getQuotaTypeList();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getQuotaTypeList() {
      let params = {
        dataType: this.dataType,
        groupId: this.ruleForm.type,
        projectId: sessionStorage.getItem("projectId")
      };
      this.$axios.get(`${this.baseURL}data_management/data_type/group/type`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("获取测点列表qqq", res.data);
          this.quotaList = res.data;
          this.ruleForm.quota = res.data[0].id;
          this.getNumList();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getQuotaList() {
      let params = {
        typeGroupId: this.ruleForm.type
      };
      this.$axios.get(`${this.baseURL}base/baseTargetType/byGroupId`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.quotaList = res.data;
          this.ruleForm.quota = res.data[0].id;
          this.getNumList();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getNumList() {
      this.$axios.get(`${this.baseURL}base/baseTarget/type/${this.projectId}/${this.ruleForm.quota}`).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.numList = res.data;
          console.log(this.numList);
          this.ruleForm.num = res.data[0].id;
          this.getNumDetail();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getNumDetail() {
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/option/${this.ruleForm.num}`).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log(res, "this.firstTime", this.firstTime);
          this.numDetail = res.data;
          this.targetId = res.data.target.id;
          this.targetName = res.data.target.code;
          this.targetUnit = res.data.target.unit;
          if (this.firstTime) {
            this.querryData();
            // this.firstTime = false;
          }
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 趋势分析结果
    getLineData() {
      // 请求折线图
      let targetId = this.targetId;
      // targetId = 79
      let params = {
        targetId,
        startTime: this.ruleForm.startTime + ":00",
        endTime: this.ruleForm.endTime + ":00",
        sort: 0, //0正序，1倒叙
        size: this.size
      };
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$axios.get(`${this.baseURL}data/history/trend/${targetId}`, { params }).then((res) => {
        res = res.data;
        console.log("趋势分析结果", res);
        let data = res.data;
        if (!data || !data.length) {
          this.loadingPage.close();
          this.showMain = false;
          return;
        }
        this.showMain = true;
        let xData = [];
        let yData = [];
        if (res.errCode === "200") {
          for (let item of data) {
            xData.push(item.time);
            yData.push(item.data[0].toFixed(3));
          }
          // console.log("mychart", xData);
          setTimeout(() => {
            this.initEcharts("myChart", xData, yData);
          }, 150);
        } else {
          this.$message.error(res.errMsg);
        }
        this.loadingPage.close();
      });
    },
    // 趋势分析数据（上）
    getLineData2() {
      // 请求折线图
      let targetId = this.targetId;
      let params = {
        targetId,
        startTime: this.ruleForm.startTime + ":00",
        endTime: this.ruleForm.endTime + ":00",
        sort: 1, //0正序，1倒叙
        size: this.size
      };
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$axios.get(`${this.baseURL}data/history/trend/base/${targetId}`, { params }).then((res) => {
        res = res.data;
        console.log("趋势分析数据", res);
        let data = res.data;
        if (!data || !data.length) {
          this.loadingPage.close();
          this.showMain = false;
          return;
        }
        let xData = [];
        let yData = [];
        if (res.errCode == "200") {
          for (let item of data) {
            // console.log("item", item.time, "item.data[0]", item.data[0]);
            xData.unshift(item.time);
            yData.unshift(item.data[0].toFixed(3));
          }
          setTimeout(() => {
            this.initEcharts("myChart2", xData, yData);
          }, 150);
          // this.initEcharts("myChart2", xData, yData);
        } else {
          this.$message.error(res.errMsg);
        }
        this.loadingPage.close();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  ::v-deep .el-form {
    margin-top: 24px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 96px;
      padding: 0;
    }

    .smallLabel {
      .el-form-item__label {
        width: 80px;
        font-weight: 300;

        &.wid96 {
          width: 96px;
        }
      }
    }

    .el-form-item {
      margin-bottom: 0;
      display: flex;

      &.mB20 {
        margin-bottom: 20px;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }

      .numSel {
        .el-input {
          width: 180px;
        }
      }
    }

    .line {
      padding: 0 10px;
    }

    .el-input {
      width: 134px;
      height: 30px;

      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }

      .el-select__caret {
        line-height: 30px;
      }

      &.el-date-editor {
        .el-input__inner {
          padding: 0 0 0 8px;
        }

        .el-icon-date {
          display: none;
        }
      }

      &.el-date-editor,
      &.el-date-editor.el-input__inner {
        width: 180px;
      }

      &.el-date-editor .el-input__icon {
        display: none;
      }
    }
  }

  ::v-deep .el-radio-group {
    .el-radio__inner {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border: 1px solid #787878;

      &:after {
        width: 6px;
        height: 6px;
      }
    }

    .el-radio__label {
      color: #fff;
      font-size: 16px;
    }
  }

  .pageMain {
    margin-top: 50px;
  }

  .chartMain {
    position: relative;
    padding-top: 17px;
    height: 280px;
    margin-top: 10px;

    .title {
      // position: absolute;
      // top: -30px;
    }

    #myChart,
    #myChart2 {
      width: 100%;
      height: 220px;
      overflow: hidden;
    }
  }

  ::v-deep .is-group.has-gutter {
    tr:nth-child(2) {
      display: none;
    }
  }
}
.title {
  position: relative;
  left: 14px;
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
}
.title::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -12px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
</style>
